import React from 'react'
import { Helmet } from 'react-helmet'

import SuggestionsForm from './forms/SuggestionsForm'
import PostSalesServiceForm from './forms/PostSalesServiceForm'
import ServiceRequestForm from './forms/ServiceRequest'
import { Icon } from 'ui'

import customerServiceBanner from '../../images/customer-service.png'
import mCustomerServiceBanner from '../../images/customer-service-mb.png'

import currencyHomeIcon from '../../assets/icons/co-currency-home.svg'
import walletIcon from '../../assets/icons/co-wallet.svg'
import commentIcon from '../../assets/icons/co-comments.svg'
import saleServiceIcon from '../../assets/icons/co-after-sale-service.svg'
import leaseServiceIcon from '../../assets/icons/co-lease-service.svg'
import administrativeOficceIcon from '../../assets/icons/co-administrative-offices.svg'
import leaseOficceIcon from '../../assets/icons/co-lease-offices.svg'
import frequentQuestionsIcon from '../../assets/icons/co-frequent-questions.svg'
import elisaIcon from '../../assets/icons/co-elisa.svg'
import virtualIcon from '../../assets/icons/co-virtual.svg'
import phoneIcon from '../../assets/icons/co-phone.svg'
import mobileIcon from '../../assets/icons/co-mobile.svg'
import downloadIcon from '../../assets/icons/co-download.svg'

function ServicesPage() {
  const showBodyCard = bodyCardId => event => {
    const bodyCardElements = document.querySelectorAll('co-body-card[visible]')
    const featuredTabElements = document.querySelectorAll(
      'co-featured-tab[activated]'
    )

    bodyCardElements.forEach(element => {
      element.removeAttribute('visible')
    })

    featuredTabElements.forEach(element => {
      element.removeAttribute('activated')
    })

    const buttonElement = event.target
    buttonElement.setAttribute('activated', 'true')

    // Look for body card element.
    const bodyCardElement = document.querySelector(bodyCardId)

    if (bodyCardElement) {
      bodyCardElement.setAttribute('visible', 'true')
    }
  }

  return (
    <div>
      <Helmet>
        <title>Servicio al Cliente | Coninsa</title>
        <meta name="description" content="En este espacio te presentamos nuestros diferentes canales de atención al cliente. Solo debes dar clic en la opción que necesites para gestionar tu solicitud." />
          <link rel="canonical" href="https://www.coninsa.co/servicio-cliente/" />
      </Helmet>
      <h1 hidden>Servicio al Cliente</h1>
      <co-banner
        class='bg-[#ede9e6] md:py-24'
        symmetric
        image-url={customerServiceBanner}
        image-mobile-url={mCustomerServiceBanner}
      >
        <outline-heading
          class='text-coninsa-blue-900 mb-0'
          level='h3'
          level-style='extralight'
        >
          Bienvenido a
        </outline-heading>
        <outline-heading
          class='text-coninsa-green-900'
          level='h2'
          level-style='bold'
        >
          Servicio al Cliente
        </outline-heading>

        <p className='text-lg md:mb-10'>
          <strong className='font-semibold'>¡Eres nuestra prioridad! </strong> <br />
          En este espacio te presentamos nuestros diferentes canales de atención al
          cliente. Solo debes dar clic en la opción que necesites para gestionar tu
          solicitud.
        </p>
      </co-banner>

      <div className='container py-4'>
        <div
          className='flex gap-3 mb-4 rounded-lg bg-neutral-white p-3 overflow-y-hidden overflow-x-auto border-coninsa-blue-300'
          style={{ boxShadow: '0px 1px 4px #00000029' }}
        >
          <co-featured-tab onClick={showBodyCard('#one')}>
            <outline-icon src={walletIcon} />
            Pagar <br />
            <strong>Mi factura</strong>
          </co-featured-tab>
          <co-featured-tab onClick={showBodyCard('#three')}>
            <outline-icon src={saleServiceIcon} />
            Servicios para cliente de <br />
            <strong>proyectos de vivienda nueva</strong>
          </co-featured-tab>
          <co-featured-tab onClick={showBodyCard('#four')}>
            <outline-icon src={leaseServiceIcon} />
            Servicios para cliente de <br />
            <strong>arrendamientos</strong>
          </co-featured-tab>
          <co-featured-tab onClick={showBodyCard('#five')}>
            <outline-icon src={administrativeOficceIcon} />
            Sedes <br />
            <strong>administrativas</strong>
          </co-featured-tab>
          <co-featured-tab onClick={showBodyCard('#six')}>
            <outline-icon src={leaseOficceIcon} />
            Sedes de <br />
            <strong> arrendamientos</strong>
          </co-featured-tab>
          <co-featured-tab onClick={showBodyCard('#seven')}>
            <outline-icon src={currencyHomeIcon} />
            Salas de <br />
            <strong>negocios</strong>
          </co-featured-tab>
          <a href='https://www.coninsa.co/preguntas-frecuentes' target='_blank'>
            <co-featured-tab onClick={showBodyCard('#eight')}>
              <outline-icon src={frequentQuestionsIcon} />
              Preguntas <br />
              <strong>frecuentes</strong>
            </co-featured-tab>
          </a>
        </div>
      </div>

      <co-body-card id='one' class='mb-5'>
        <div className='max-w-4xl mx-auto md:grid grid-cols-2 md:pb-3'>
          <div>
            <outline-heading
              level='h5'
              level-style='bold'
              class='text-coninsa-blue-900'
            >
              Paga tu factura de forma rápida
            </outline-heading>
            <p className='text-sm md:text-base mb-4 md:mb-0'>
              Ahora puedes pagar tu factura mucho más rápido ingresando a{' '}
              <strong className='font-semibold'>
                Pago rápido de facturas en Mi Coninsa Virtual.
              </strong>
            </p>
          </div>
          <div className='flex items-center justify-center'>
            <co-button
              caps
              variant='secondary'
              onClick={() =>
                window.open(
                  'https://miconinsavirtual.coninsa.co/pagar-factura',
                  '_blank'
                )
              }
            >
              IR A PAGO RÁPIDO
            </co-button>
          </div>
        </div>
      </co-body-card>

      <co-body-card id='two' class='mb-5'>
        <div className='max-w-4xl mx-auto'>
          <outline-heading
            level='h5'
            level-style='bold'
            class='text-coninsa-blue-900'
          >
            ¿Tienes algún comentario o sugerencia?
          </outline-heading>

          <SuggestionsForm />
        </div>
      </co-body-card>

      <co-body-card id='three' class='mb-5'>
        <div className='max-w-4xl mx-auto'>
          <outline-heading
            level='h5'
            level-style='bold'
            class='text-coninsa-blue-900'
          >
            ¿Requieres hacer una solicitud sobre el proceso de tu inmueble?
          </outline-heading>

          <PostSalesServiceForm />
        </div>
      </co-body-card>

      <co-body-card id='four' class='mb-5'>
        <div className='max-w-4xl mx-auto'>
          <outline-heading
            level='h5'
            level-style='bold'
            class='text-coninsa-blue-900'
          >
            ¿Necesitas algún servicio para tu inmueble en arriendo?
          </outline-heading>

          <ServiceRequestForm />
        </div>
      </co-body-card>

      <co-body-card id='five' class='mb-5'>
        <outline-heading
          level='h5'
          level-style='bold'
          class='text-coninsa-blue-900 mb-8 md:text-center'
        >
          Conoce toda la información de nuestras sedes administrativas
        </outline-heading>

        <div className='divide-y md:divide-y-0 md:grid grid-cols-4 mb-5 [&_p]:mb-2 [&_div]:py-4 lg:[&_div]:py-0'>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              ANTIOQUIA
            </outline-heading>
            <div className='flex'>
              <outline-icon
                src={phoneIcon}
                size='23'
                class='flex items-center mr-3'
              />
              <p className='text-xl font-bold m-0'>(604) 448 88 28</p>
            </div>
            <p>
              <strong className='font-bold'>Centro, Medellín</strong> <br /> Calle 55
              # 45 - 55
            </p>
            <p>
              <strong className='font-bold'>El Poblado, Medellín</strong> <br />{' '}
              Carrera 39 # 5 A - 95
            </p>
          </div>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BARRANQUILLA
            </outline-heading>
            <div className='flex'>
              <outline-icon
                src={phoneIcon}
                size='23'
                class='flex items-center mr-3'
              />
              <p className='text-xl font-bold m-0'>(605) 319 96 10</p>
            </div>
            <p>
              <strong className='font-bold'>Calle 93</strong> <br /> Calle 93 # 47 -
              53
            </p>
          </div>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BOGOTÁ
            </outline-heading>
            <div className='flex'>
              <outline-icon src={phoneIcon} class='flex items-center mr-3' />
              <p className='text-xl font-bold m-0'>(601) 601 48 00</p>
            </div>
            <p>
              <strong className='font-bold'>Santa Bárbara</strong> <br /> Av. Carrera
              19 # 114 - 65 Piso 6
            </p>
          </div>
        </div>

        <div className='bg-coninsa-gray-300 p-5'>
          <outline-heading
            level='h5'
            level-style='bold'
            class='text-coninsa-blue-900 text-center font-body mb-5 md:mb-8'
          >
            HORARIOS DE ATENCIÓN
          </outline-heading>
          <div className='[&_div]:min-w-[180px] flex gap-4 md:grid grid-cols-4 text-sm [&_p]:mb-2 [&_div]:py-4 lg:[&_div]:py-0 overflow-y-hidden overflow-x-auto'>
            <div>
              <div className='font-bold text-coninsa-blue-900'>Centro, Medellín</div>
              <p>
                <strong className='font-bold'>Lunes a jueves:</strong> <br />
                7:30 a.m. a 6:00 p.m.
              </p>
              <p>
                <strong className='font-bold'>Viernes:</strong> <br />
                7:30 a.m. a 5:00 p.m.
              </p>
              <p>
                <strong className='font-bold'>Sábados:</strong> <br />
                8:00 a.m. a 12:30 p.m. <br /> <em>Solo para arrendamientos</em>
              </p>
            </div>

            <div>
              <div className='font-bold text-coninsa-blue-900'>
                El Poblado, Medellín
              </div>
              <p>
                <strong className='font-bold'>Lunes a jueves:</strong> <br />
                8:00 a.m. a 5:30 p.m.
              </p>
              <p>
                <strong className='font-bold'>Viernes:</strong> <br />
                8:00 a.m. a 5:00 p.m.
              </p>
              <p>
                <strong className='font-bold'>Sábados:</strong> <br />
                8:00 a.m. a 12:30 p.m. <br /> <em>Solo para arrendamientos</em>
              </p>
            </div>

            <div>
              <div className='font-bold text-coninsa-blue-900'>
                Calle 93, Barranquilla
              </div>
              <p>
                <strong className='font-bold'>Lunes a viernes:</strong> <br />
                8:00 a.m. a 5:30 p.m.
              </p>
              <p>
                <strong className='font-bold'>Sábados:</strong> <br />
                8:00 a.m. a 4:00 p.m. <br /> <em>Solo para arrendamientos</em>
              </p>
            </div>

            <div>
              <div className='font-bold text-coninsa-blue-900'>
                Santa Bárbara, Bogotá
              </div>
              <p>
                <strong className='font-bold'>Lunes a jueves:</strong> <br />
                7:00 a.m. a 5:00 p.m.
              </p>
              <p>
                <strong className='font-bold'>Viernes:</strong> <br />
                7:00 a.m. a 4:00 p.m.
              </p>
              <p>
                <strong className='font-bold'>Sábados:</strong> <br />
                8:00 a.m. a 12:00 m. <br /> <em>Solo para arrendamientos</em>
              </p>
            </div>
          </div>
        </div>
      </co-body-card>

      <co-body-card id='six' class='mb-5'>
        <outline-heading
          level='h5'
          level-style='bold'
          class='text-coninsa-blue-900 mb-7 md:text-center'
        >
          Conoce toda la información de nuestras sedes de arrendamientos
        </outline-heading>
        <div className='divide-y md:divide-y-0 md:grid grid-cols-3 mb-5 [&_p]:mb-2 [&_div]:py-4 lg:[&_div]:py-0'>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              ANTIOQUIA
            </outline-heading>
            <p className='flex'>
              <outline-icon src={mobileIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>+57 (324) 100 00 75</span>
            </p>
            <p className='flex'>
              <outline-icon src={phoneIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>(604) 448 88 28</span>
            </p>
            <p>
              <strong className='font-bold'>Centro, Medellín</strong> <br /> Calle 55
              # 45 - 55
            </p>
            <p>
              <strong className='font-bold'>El Poblado, Medellín</strong> <br />{' '}
              Carrera 39 # 5 A - 95 - Ed. Avantgarde
            </p>
            <p>
              <strong className='font-bold'>Envigado</strong> <br /> Carrera 27 # 35
              Sur - 180 Local 217 C.C. Terracina Plaza
            </p>
            <p>
              <strong className='font-bold'>Sabaneta</strong> <br /> Calle 69 Sur #
              45 - 43 - Mall Sabaneta
            </p>
            <p>
              <strong className='font-bold'>Rionegro</strong> <br /> Km 8 Complex
              Llanogrande Local 36
            </p>
            <p>
               <strong className='font-bold'>Laureles</strong> <br /> Calle 33A #76-22
            </p>
          </div>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BARRANQUILLA
            </outline-heading>
            <p className='flex mb-2'>
              <outline-icon src={mobileIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>+57 (324) 100 00 89</span>
            </p>
            <p className='flex'>
              <outline-icon src={phoneIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>(605) 319 96 10</span>
            </p>
            <p>
              <strong className='font-bold'>Calle 93</strong> <br />
              Calle 93 # 53 - 47
            </p>
          </div>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BOGOTÁ
            </outline-heading>
            <p className='flex'>
              <outline-icon src={mobileIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>+57 (324) 100 00 82</span>
            </p>
            <p className='flex'>
              <outline-icon src={phoneIcon} class='flex items-center mr-3' />
              <span className='text-xl font-bold'>(601) 601 48 00</span>
            </p>
            <p>
              <strong className='font-bold'>Santa Bárbara</strong> <br /> Av. Av.
              Carrera 19 # 114 - 65 Piso 6
            </p>
          </div>
        </div>

        <div className='bg-coninsa-gray-300 p-5 mb-4'>
          <div className='[&_div]:min-w-[180px] flex gap-4 md:grid grid-cols-3 [&_p]:mb-2 [&_div]:py-4 lg:[&_div]:py-0 overflow-y-hidden overflow-x-auto'>
            <div>
              <p className='text-coninsa-blue-900 m-0 leading-5'>
                <strong className='font-bold'>Horarios de atención </strong>
                <br />
                Antioquia
              </p>
              <div className='block md:grid grid-cols-2 text-sm'>
                <p>
                  <strong className='font-bold'>Lunes a viernes:</strong> <br />
                  8:00 a.m. a 5:30 p.m.
                </p>
                <p>
                  <strong className='font-bold'>Sábados:</strong> <br />
                  8:00 a.m. a 1:30 p.m.
                </p>
              </div>
            </div>

            <div>
              <p className='text-coninsa-blue-900 m-0 leading-5'>
                <strong className='font-bold'>Horarios de atención </strong>
                <br />
                Barranquilla y Bogotá
              </p>

              <div className='block md:grid grid-cols-2 text-sm'>
                <p>
                  <strong className='font-bold'>Lunes a viernes:</strong> <br />
                  8:00 a.m. a 5:30 p.m.
                </p>
                <p>
                  <strong className='font-bold'>Sábados:</strong> <br />
                  8:00 a.m. a 1:30 p.m.
                </p>
              </div>
            </div>

            <div className='hidden md:flex mb-2'>
              <a
                className='flex items-center text-xl font-bold m-0 no-underline'
                href='mailto:arrendamientos@coninsa.co'
              >
                <Icon name='fa-envelope' sx={{ color: 'neutral', mr: '14px' }} />
                <span className='text-coninsa-blue-300'>
                  arrendamientos@coninsa.co
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className='flex md:hidden mb-2'>
          <a
            className='flex items-center text-lg font-bold m-0 no-underline text-coninsa-blue-300'
            href='mailto:arrendamientos@coninsa.co'
          >
            <Icon name='fa-envelope' sx={{ color: 'neutral', mr: '14px' }} />
            <span className='text-coninsa-blue-300'>arrendamientos@coninsa.co</span>
          </a>
        </div>
      </co-body-card>

      <co-body-card id='seven' class='mb-5'>
        <outline-heading
          level='h5'
          level-style='bold'
          class='text-coninsa-blue-900 mb-7 md:text-center'
        >
          Conoce toda la información de nuestras Salas de Negocios a nivel nacional
        </outline-heading>
        <div className='divide-y md:divide-y-0 grid md:grid-cols-2 lg:grid-cols-4 mb-5 [&_p]:mb-2 [&_div]:py-4 lg:[&_div]:py-0'>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              ANTIOQUIA
            </outline-heading>
            <p>
              <strong className='font-bold'>Lunes a domingo </strong> <br />
              10:00 a.m. a 7:00 p.m.
            </p>
          </div>

          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BARRANQUILLA
            </outline-heading>
            <p>
              <strong className='font-bold'>Lunes a viernes</strong> <br />
              9:30 a.m. a 5:30 p.m.
            </p>
            <p>
              <strong className='font-bold'>Sábados</strong> <br />
              10:00 a.m. a 5:00 p.m.
            </p>
            <p>
              <strong className='font-bold'>Domingos y festivos</strong> <br />
              10:00 a.m. a 4:00 p.m.
            </p>
          </div>
          <div>
            <outline-heading
              level='h5'
              level-style='semibold'
              class='text-coninsa-blue-900 font-display'
            >
              BOGOTÁ
            </outline-heading>
            <p>
              <strong className='font-bold'>Lunes a domingo</strong> <br />
              10:00 a.m. a 7:00 p.m.
            </p>
          </div>
        </div>

        <div className='bg-coninsa-gray-300 py-6 px-4 md:p-7 flex justify-center'>
          <a
            className='flex items-center border border-solid border-coninsa-blue-300 font-bold no-underline text-coninsa-blue-300 text-xs md:text-base bg-coninsa-white-300 p-2 md:px-4 rounded'
            href='https://www.coninsa.co/directorio-salas-de-negocios-de-proyectos'
            target='_blank'
          >
            <outline-icon class='mr-2 md:mr-3' src={downloadIcon} />
            <span>LISTADO DE TELÉFONOS Y DIRECCIONES</span>
          </a>
        </div>
      </co-body-card>

      <div className='container px-2 mb-4'>
        <div className='grid lg:grid-cols-3 gap-4'>
          <co-ad-card>
            <h5
              slot='content'
              className='font-display font-extralight mb-6 text-coninsa-blue-900 text-[26px] md:text-3xl'
            >
              <strong className='font-semibold'>¿NECESITAS AYUDA?</strong>
            </h5>

            <div slot='content' className='flex items-center'>
              <outline-icon src={virtualIcon} class='w-[200px] rounded-full' />
              <p
                slot='content'
                className='font-medium text-left ml-2 md:text-xl md:leading-5'
              >
                Habla con la asistente virtual Coninsa{' '}
                <span className='font-bold'>
                  a través de{' '}
                  <a
                    href='https://api.whatsapp.com/send?phone=573123636333&text=Hola!'
                    className='text-coninsa-green-900'
                    target='_blank'
                  >
                    WhatsApp
                  </a>
                </span>
              </p>
            </div>
          </co-ad-card>

          <co-ad-card>
            <h5
              slot='content'
              className='font-display font-extralight mb-8 text-coninsa-blue-900 text-[26px] text-3xl leading-8'
            >
              <strong className='font-semibold'>PROYECTOS NUEVOS</strong> PARA
              COMPRAR
            </h5>

            <co-button
              class='text-xs md:text-base'
              slot='content'
              caps
              variant='secondary'
              onClick={() => window.open('/proyectos', '_blank')}
            >
              CONOCE NUESTROS PROYECTOS
            </co-button>
          </co-ad-card>

          <co-ad-card>
            <h5
              slot='content'
              className='font-display font-extralight mb-8 text-coninsa-blue-900 text-[26px] md:text-3xl leading-8'
            >
              ¿NECESITAS UN INMUEBLE{' '}
              <strong class='font-semibold'>EN ARRIENDO?</strong>
            </h5>

            <co-button
              slot='content'
              caps
              variant='secondary'
              onClick={() => window.open('/inmuebles/arriendo', '_blank')}
            >
              TODOS LOS INMUEBLES
            </co-button>
          </co-ad-card>
        </div>
      </div>
    </div>
  )
}

export default ServicesPage
