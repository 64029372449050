import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Container from "@coninsa-s2/container";
import Button from "@coninsa-s2/button";
import SearchSummary from "./sections/search-summary";

import "./styles.css";

export interface ProjectSearchType {
  id: number;
  name: string;
  latLong: string;
  price: number | string;
}

const defaultNavigate = (url) => {
  console.log(url);
};

interface T_props {
  navigate: (string) => void;
  searchBar: React.FC;
  itemCard: React.FC;
  configs: any;
}

const SearcView: React.FC<T_props> = ({
  navigate = defaultNavigate,
  searchBar: SearchBar,
  itemCard: ItemCard,

  configs,
}) => {
  const { loading, data, refetch, fetchMore } = useQuery(configs.searchQuery, {
    variables: {
      text: [],
      and_conditions: [],
      offset: 0,
      sort: configs.sort.defaultFields,
    },
    notifyOnNetworkStatusChange: true,
  });

  const items: Array<ProjectSearchType> = (data?.search?.documents ?? []).map(
    (item) => ({
      ...item,
    })
  );

  const resultCount = data?.search?.result_count ?? 0;

  const loadMore = () =>
    fetchMore({
      variables: { offset: items.length },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const documents = fetchMoreResult.search.documents;

        return {
          search: {
            ...fetchMoreResult.search,
            documents: [...previousResult.search.documents, ...documents],
          },
        };
      },
    });

  return (
    <div className="s2-search-view">
      <SearchBar
        initialFacets={data?.search?.facets}
        refetch={refetch}
        navigate={navigate}
        configs={configs}
      />

      <Container className="pb-12">
        {resultCount > 0 && (
          <>
            <SearchSummary loading={loading} resultCount={resultCount} />

            <div className="s2-search-view__content">
              <div className="s2-search-view__list !grid-cols-4">
                {items.map((item) => (
                  <ItemCard key={item.id} {...item} />
                ))}
              </div>

              {data && data?.search?.result_count != items.length && (
                <div className="col-span-2 flex justify-center pt-8">
                  <Button
                    className="w-60"
                    type="button"
                    onClick={loadMore}
                    disabled={loading}
                  >
                    {!loading ? configs.loadMoreLabel : "Cargando ..."}
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SearcView;
