import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import Container from "@coninsa-s2/container";
import Button from "@coninsa-s2/button";
import { dom } from "@coninsa-s2/utils";

import SearchMap from "./sections/search-map";
import SearchSummary from "./search-summary";
import SuggestionForm from "./sections/suggestion-form";
import SuggestionFormModal from "./sections/suggestion-form-modal";

import {
  CurrencyContext,
  CurrencySelectors,
  getFormattedCurrencyValue,
} from "@coninsa-s2/currency";

import "./styles.css";

export interface ProjectSearchType {
  id: number;
  name: string;
  latLong: string;
  price: number | string;
}

const defaultNavigate = (url) => {
  console.log(url);
};

interface SearcViewProps {
  navigate: (string) => void;
  searchBar: React.FC;
  itemCard: React.FC;
  popupCard: React.FC;
  searchQuery: any;
  configs: any;
}

const SearcView: React.FC<SearcViewProps> = ({
  navigate = defaultNavigate,
  searchBar: SearchBar,
  itemCard: ItemCard,
  popupCard: PopupCard,

  configs,
}) => {
  const currency = useContext(CurrencyContext);
  const [expanded, setExanded] = useState(false);
  const { loading, data, refetch, fetchMore } = useQuery(configs.searchQuery, {
    variables: {
      text: [],
      and_conditions: [],
      offset: 0,
      sort: configs.sort.defaultFields,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    dom.setPageScrolling(expanded);
  }, [expanded]);

  const items: Array<ProjectSearchType> = (data?.search?.documents ?? []).map(
    (item) => ({
      ...item,
      price: getFormattedCurrencyValue(item?.price, currency),
    })
  );

  const nItems = items.filter((item) => item?.latLong);
  const resultCount = data?.search?.result_count ?? 0;

  const loadMore = () =>
    fetchMore({
      variables: { offset: items.length },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const documents = fetchMoreResult.search.documents;

        return {
          search: {
            ...fetchMoreResult.search,
            documents: [...previousResult.search.documents, ...documents],
          },
        };
      },
    });

  return (
    <div className="s2-search-view">
      <SearchBar
        resultCount={resultCount}
        loading={loading}
        initialFacets={data?.search?.facets}
        refetch={refetch}
        navigate={navigate}
        configs={configs}
      />

      <Container className="pb-12">
        {resultCount > 0 && (
          <div className="flex justify-end pt-5">
            <CurrencySelectors
              align="right"
              className="self-end md:max-w-md md:items-end"
            />
          </div>
        )}

        {resultCount > 0 && (
          <>
            <SearchSummary
              loading={loading}
              resultCount={resultCount}
              refetch={refetch}
              sortSettings={configs.sort}
            />

            <div className="s2-search-view__content grid gap-8 md:grid-cols-2 lg:grid-cols-12 lg:items-start">
              <div className="s2-search-view__list">
                {items.map((item) => (
                  <ItemCard key={item.id} {...item} withoutPriceFormatting />
                ))}

                {data && data?.search?.result_count != items.length && (
                  <div className="col-span-2 flex justify-center pt-8">
                    <Button
                      className="w-60"
                      type="button"
                      onClick={loadMore}
                      disabled={loading}
                    >
                      {!loading ? configs.loadMoreLabel : "Cargando ..."}
                    </Button>
                  </div>
                )}
              </div>

              {nItems.length > 0 && (
                <div
                  className={clsx("s2-search-view__map-actions", {
                    "s2-search-view__map-actions--fulled": expanded,
                  })}
                >
                  <Button
                    variant="static"
                    className="w-40"
                    disabled={loading}
                    size="large"
                    onClick={() => setExanded(!expanded)}
                  >
                    {expanded ? "Ocultar Mapa" : "Ver Mapa"}
                  </Button>
                </div>
              )}

              <div
                className={clsx(
                  "s2-search-view__map lg:sticky lg:top-4 lg:col-span-5",
                  { "s2-search-view__map--fulled": expanded }
                )}
              >
                <SearchMap
                  items={nItems}
                  className="h-screen lg:h-[724px]"
                  popupComponent={PopupCard}
                  expanded={expanded}
                  withoutPriceFormatting
                />
              </div>
            </div>
          </>
        )}
        {!loading && resultCount === 0 && (
          <div className="md:mx-auto md:w-3/6">
            <div className="mb-5 pt-12">
              <h3 className="mb-2 text-lg font-bold text-s2-blue">
                No hay resultados
              </h3>
              <p className="text-sm text-slate-600">
                ¿No has encontrado lo que buscabas? Déjanos tus datos para
                ayudarte en tu búsqueda.
              </p>
            </div>
            <SuggestionForm baseUrl={configs.baseUrl} />
          </div>
        )}

        {!loading && resultCount === 0 && (
          <SuggestionFormModal baseUrl={configs.baseUrl} />
        )}
      </Container>
    </div>
  );
};

export default SearcView;
