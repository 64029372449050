import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import Button from "@coninsa-s2/button";

function SuggestionForm({ baseUrl }) {
  const { register, handleSubmit, reset, watch } = useForm({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const callback = () => {
      localStorage.setItem("suggestion_form.status", "dirty");
    };

    const subscription = watch(callback);

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    localStorage.setItem("suggestion_form.status", "clean");
  }, []);

  const onSubmit = async (data) => {
    localStorage.setItem("suggestion_form.status", "dirty");

    let branchOffice = "";

    if (data.regional === "Bogotá") {
      branchOffice = "ABR BOGOTA CEDRITOS";
    } else if (data.regional === "Medellín") {
      branchOffice = "ABR MEDELLIN CENTRO";
    } else if (data.regional === "Barranquilla") {
      branchOffice = "ABR BARRANQUILLA LA 93";
    } else {
      return "";
    }

    let zohoInfo = {
      data: [
        {
          First_Name: data.name,
          Last_Name: data.last_name,
          Owner: { id: "" }, // Se asignará según el tipo de propiedad
          Email: data.email,
          Observaciones_del_cliente: `${data.property_for} - ${data.observations}`,
          Mobile: data.phone,
          Sucursal: branchOffice,
          Regional: data.regional,
          Origen_de_Informaci_n: "Digital",
          Medio_Publicitario: "www.coninsa.co",
          Creador_API: "www.coninsa.co",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Layout: {
            id: "", // Se asignará según el tipo de propiedad
          },
        },
      ],
    };

    // Asignar valores específicos basados en data.property_for
    if (data.property_for === "Comprar proyecto nuevo") {
      zohoInfo.data[0].Owner.id = "2528071000220694570";
      zohoInfo.data[0].Layout.id = "2528071000000091055";
      zohoInfo.data[0].Proyecto_de_interes = {
        id: "2528071000374517009",
      };
    } else {
      zohoInfo.data[0].Owner.id = "2528071000008997151";
      zohoInfo.data[0].Layout.id = "2528071000003925032";
      zohoInfo.data[0].Servicio_interes = "Arriendo"; // Opciones Arriendo o Venta
    }

    

    const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then((response) => {
          reset();
          setSubmitted(true);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <div>
      {!submitted && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-content">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4 md:flex-row">
                <input
                  className="s2-input"
                  type="text"
                  {...register("name")}
                  id="name"
                  placeholder="Nombres"
                  required
                />
                <input
                  className="s2-input"
                  type="text"
                  {...register("last_name")}
                  id="last_name"
                  placeholder="Apellidos"
                  required
                />
              </div>

              <div className="grid grid-cols-2 gap-4 md:flex-row">
                <input
                  className="s2-input"
                  type="tel"
                  {...register("phone")}
                  id="phone"
                  placeholder="Número de celular"
                  required
                />
                <input
                  className="s2-input"
                  type="text"
                  {...register("email")}
                  id="email"
                  placeholder="Correo electrónico"
                  required
                />
              </div>

              <div className="">
                <textarea
                  className="s2-textarea w-full"
                  rows={5}
                  id="observations"
                  {...register("observations")}
                  placeholder="Escribe lo que buscabas"
                  required
                ></textarea>
              </div>

              <div className="grid grid-cols-2 gap-4 md:flex-row">
                <select
                  {...register("regional")}
                  className="s2-select"
                  id="regional"
                  required
                >
                  <option value="">Regional</option>
                  <option value="Bogotá">Bogotá</option>
                  <option value="Medellín">Medellín</option>
                  <option value="Barranquilla">Barranquilla</option>
                </select>
                <select
                  {...register("property_for")}
                  className="s2-select"
                  id="property_for"
                  required
                >
                  <option value="">Quieres un inmueble para:</option>
                  <option value="Comprar proyecto nuevo">
                    Comprar proyecto nuevo
                  </option>
                  <option value="Arrendar un inmueble">
                    Arrendar un inmueble
                  </option>
                  <option value="Comprar un usado">Comprar un usado</option>
                </select>
              </div>

              <div className="flex justify-end">
                <Button shape="square">Enviar</Button>
              </div>
            </div>
          </div>
        </form>
      )}

      {submitted && (
        <div className="pt-12">
          <p className="mb-6 text-center font-medium text-slate-800">
            Gracias por confiar en nosotros, pronto un asesor se comunicará con
            usted.
          </p>

          <div className="flex justify-center">
            <Button href={baseUrl} shape="square" variant="ghost">
              Hacer otra búsqueda
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuggestionForm;
