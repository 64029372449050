import React from "react";
import { graphql } from "gatsby";
import Layout from "../../layout/default/Layout";
import { Helmet } from "react-helmet";
import { Router, navigate } from "@reach/router";

import { SearchFeaturedPropertyPage } from "@coninsa-site/property";
import FloatingWhatsappBtn from "modules/home/components/FloatingWhatsappBtn";

function Landing({ data }) {
  return (
    <Layout
      headerAds={data?.drupal?.ads?.header}
      popupAds={data?.drupal?.ads?.popUp}
      footerAds={data?.drupal?.ads?.footer}
    >
      <Helmet>
        <title>Arrendamiento de inmuebles destacados en Colombia | Coninsa</title>
        <meta name="description" content="Descubre la mejor selección de viviendas en arrendamiento en Colombia con Coninsa. Encuentra apartamentos y casas en alquiler en las mejores ubicaciones, con opciones que se adaptan a todas tus necesidades y presupuesto. Confía en nuestra experiencia y servicio para encontrar tu próximo hogar." />
        <link rel="canonical" href="https://www.coninsa.co/arrendamientos/vivienda/" />
      </Helmet>

      <FloatingWhatsappBtn path="https://api.whatsapp.com/send?phone=573123636333&text=Hola!" />

      <Router basepath="/inmuebles/destacados">
        <SearchFeaturedPropertyPage path="*" navigate={navigate} />
      </Router>
    </Layout>
  );
}

export const query = graphql`
  query FeaturedPageQuery {
    drupal {
      ads: getAdsByUrl(path: "/inmuebles/destacados") {
        header {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        popUp {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
        footer {
          id: nid
          image: fieldImage {
            url
          }
          url: fieldUrl {
            uri
            title
          }
        }
      }
    }
  }
`;

export default Landing;
