export interface T_zoho_detail {
  owner?: string;
  interestedProject?: string;
  regional?: string;
}

export default async function zohoAPI(
  data,
  quotationUrl,
  zohoDetail: T_zoho_detail
) {
  const hasSavings = parseInt(data.has_savings) === 1 ? "Si" : "No";
  const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";

  const zohoInfo = {
    data: [
      {
        First_Name: data.user.names,
        Last_Name: data.user.last_name,
        Owner: zohoDetail?.owner,
        Email: data.user.email,
        Observaciones_del_cliente: `Cesantías: ${hasSavings}`,
        Mobile: data.user.phone,
        Regional: zohoDetail?.regional,
        Origen_de_Informaci_n: "Digital",
        Medio_Publicitario: "Simulador web",
        Creador_API: "www.coninsa.co",
        Tipo_de_Canal: "Digital",
        Tipo_de_Contacto: "Otros",
        Layout: {
          id: "2528071000000091055",
        },
        Proyecto_de_interes: zohoDetail?.interestedProject,
        Simulaci_n_pdf: quotationUrl,
      },
    ],
  };

  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zohoInfo),
  });
}

export async function getProject(code) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `https://admindrupal.coninsa.co/cotizador/projects/${code}`,
    options
  );
  const dataResponse = await response.json();

  return dataResponse;
}
