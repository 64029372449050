import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import HeroBanner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Button from "@coninsa-s2/button";

import { SEARCH_PROJECTS_QUERY } from "./queries";
import bannerImage from "../../../images/project-banner.jpg";
import bannerMobileImage from "../../../images/project-banner-mobile.jpg";

import { getFacetIndexOptions, getFacetBuildingTypes } from "../../utils";
import searchProjectsConfigs from "../../facets/search-preojects.ts";
import LoupeIcon from "../../../../home/icons/loupe.inline.svg";

function Banner() {
  const [locations, setLocations] = useState([]);
  const [types, setTypes] = useState([]);
  const [locationValue, setLocationValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const { loading, data, refetch } = useQuery(SEARCH_PROJECTS_QUERY, {
    variables: { text: "" },
  });

  useEffect(() => {
    if (locationValue) {
      refetch({ text: locationValue?.value || "" });
      setButtonDisabled(false);

      // Reset type select
      setTypeValue(null);
    }
  }, [locationValue]);

  useEffect(() => {
    if (data?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, data.search.facets));
      }

      setTypes(getFacetBuildingTypes(data.search.facets));
    }
  }, [data]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      property_type: typeValue?.value || "",
    };

    const isValidOption = locations.find(
      (item) => item.value === locationValue?.value
    );

    if (!isValidOption) {
      data.text = locationValue?.value || "";
    } else {
      data.department_project = locationValue?.value;
    }

    const url = searchProjectsConfigs.getPrettyPaths(data);
    window.location.replace(url, "_self");
  };

  return (
    <HeroBanner imageUrl={bannerImage} imageUrlMobile={bannerMobileImage}>
      <Container>
        <Heading as="h1" tint="white" size="4xl" className="s2-banner__label">
          ¡Encuentra tu inmueble ideal <br /> para vivir o invertir!
        </Heading>

        <form className="s2-banner__form" onSubmit={handleSubmit}>
          <div style={{ flex: 2 }}>
            <CreatableSelect
              className="s2-banner__select-first"
              classNamePrefix="s2-select"
              createOptionPosition="first"
              value={locationValue}
              options={locations}
              placeholder="Busca por nombre del departamento, ciudad, barrio o palabras claves"
              variant="search"
              isLoading={loading}
              isDisabled={loading}
              formatCreateLabel={(text) => `Buscar '${text}'`}
              onChange={(selected) => {
                if (selected) {
                  setLocationValue(selected);
                }
              }}
            />
          </div>

          <div style={{ flex: 0.75 }}>
            <Select
              className="s2-banner__select-last"
              classNamePrefix="s2-select"
              value={typeValue}
              placeholder="Tipo de inmueble"
              options={types}
              isLoading={loading}
              isDisabled={loading || isButtonDisabled}
              variant="large"
              onChange={(selected) => {
                if (selected) {
                  setTypeValue(selected);
                }
              }}
            />
          </div>

          <Button className="s2-banner__btn-mobile" size="fluid">
            Buscar inmueble
          </Button>

          <button
            className="s2-banner__btn-desktop"
            type="submit"
            aria-label="Buscar"
          >
            <LoupeIcon />
          </button>
        </form>
      </Container>
    </HeroBanner>
  );
}

export default Banner;
